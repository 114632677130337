@import '~assets/styles/variables.sass'

.add-payroll-page
  &__blocks
    display: flex
    gap: 16px
    flex-wrap: wrap
    margin-bottom: 16px
  &__block
    flex: 0 0 calc((100% - 32px) / 3)
    padding: 24px
    background: #FFFFFF
    border: 1px solid #F0F0F0
    border-radius: 10px
  &__fill-the-table
    min-height: 48px
    margin-top: 24px
    margin-left: auto

  &__paid
    display: flex
    align-items: center
    gap: 24px
    font: 1.6rem $b
    justify-content: flex-end
    margin-top: 33px