@import '~assets/styles/variables.sass'

.back-button
  display: flex
  align-items: center
  gap: 25px
  margin-bottom: 20px
  cursor: pointer
  &__label
    font: 1.8rem/1 $sb
  &__link
    height: 18px

