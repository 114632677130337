@import '~assets/styles/variables.sass'

.schedules-page
  &__schedules
    display: flex
    flex-wrap: wrap
    gap: 24px
    @media only screen and (max-width: 1200px) 
      justify-content: center
    &--item
      max-width: 317px
      min-height: 391px
      width: 100%
  &__date-wrapper
    display: flex
    gap: 16px
    align-items: center
  &__date-input
    display: none
  &__date
    width: 108px
    height: 46px
    background: #FFFFFF
    border-radius: 6px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    user-select: none
    font: 1.6rem $m
    border: 1px solid transparent
    &.checked
      border: 1px solid $primary
      color: $primary

  .ui-accordion__loader
    margin-top: 60px

  .MuiAccordionDetails-root
    margin: 0 !important
    padding: 0px 24px 20px !important
