@import '~assets/styles/variables.sass'

.reports-modal
  .ui-tabs
    margin-bottom: 2.4rem

  .MuiTabs-flexContainer
    gap: 2.4rem
    border-bottom: 1px solid $gray

  .ui-tabs__tab
    padding-top: 0 !important
    padding-left: 0 !important
    padding-right: 0 !important
    &.Mui-selected
      color: $primary !important

  .MuiTabs-indicator
    border: 0 !important
    border-bottom: 3px solid $primary !important

