@import '~assets/styles/variables.sass'

.header
  height: $header-height
  border-bottom: 1px solid $gray
  display: flex
  justify-content: space-between
  align-items: center
  background: white
  &-z
    position: relative
    z-index: 9999999
    background: white
  &__nav
    display: flex
    height: 100%
    align-items: center
  &-item
    cursor: pointer
    &__inner
      position: relative
      &-counter
        width: 18px
        height: 18px
        background: red
        border-radius: 50%
        position: absolute
        left: 50%
        top: -7px
        transform: translateX(calc(-50% + 8px))
        font-size: 10px
        display: flex
        justify-content: center
        align-items: center
        color: $white

  &__right
    display: flex
    height: 100%
    .header-item:first-child:not(.header-item__active) 
      svg path
        stroke: transparent
        fill: #B0B8CC

  &__notification-modal
    background: rgba(0, 0, 0, 0.15)
    &-paper
      box-shadow: none !important
      position: absolute !important
      top: 0 !important
      transform: translateX(7px) !important
      width: 523px
      max-height: 100vh !important
      height: 100vh
      right: 0 !important
      left: initial !important
      border-radius: 0 !important
      padding: 96px 0 0 !important
      transition-duration: .4s !important
    &-list
      padding: 0 !important
      height: 100% !important

  &__account
    background: rgba(0, 0, 0, 0.15)
    &-paper
      padding: 24px
      box-shadow: none !important
      position: absolute !important
      top: 105px !important
      left: initial !important
      right: 8px !important
      width: 309px
      border-radius: 12px !important
      transition-delay: .4s !important
    &-name
      white-space: nowrap
      font: 1.8rem $b
      margin: 0
    &-username
      text-overflow: ellipsis
      overflow: hidden
      color: $text-gray
      font: 1.6rem $m
      margin-top: 2px
    &-logout
      width: 100%
    &-list
      padding: 0 !important
    &-edit
      display: flex
      gap: 8px
      align-items: center
      color: $primary
      font: 1.6rem $m
      margin: 8px 0 32px
      cursor: pointer
      svg path
        stroke: $primary
