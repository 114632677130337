@import '~assets/styles/variables.sass'
  
.add-crew-modal
  &__row
    margin-bottom: 16px !important
    &:last-child
      margin-bottom: 0 !important

  &__member-name
    font: 1.3rem $m
    margin: 0
    color: $dark