@import '~assets/styles/variables.sass'
.toggle-switch
  $switch-width: 51px
  $switch-height: 30px
  $switch-padding: 2px

  position: relative
  display: inline-block
  width: $switch-width
  height: $switch-height

  input
    opacity: 0
    width: 0
    height: 0

    &:checked + .slider
      background-color: $primary

    &:checked + .slider:before
      transform: translateX($switch-width - $switch-height)

    &:focus + .slider
      box-shadow: 0 0 1px $primary
      box-shadow: 5px black

    &:disabled + .slider
      opacity: 0.5
      cursor: not-allowed

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $light-gray
    transition: .4s
    border-radius: $switch-height
    border: none

    &:before
      position: absolute
      content: ""
      height: $switch-height - (2 * $switch-padding)
      width: $switch-height - (2 * $switch-padding)
      left: $switch-padding
      bottom: $switch-padding
      background-color: white
      transition: .4s
      border-radius: 50%
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2)
      border: none
