@import '~assets/styles/variables'

.workers-table
  .ui-table__td
    &:first-child
      padding-left: 25px
      padding-right: 0

  &__time
    width: 140px
    @media (max-width: 1170px)
      width: 100px

  &__specialities
    width: 125px
    overflow: hidden
    font: 1.2rem $m
    color: $text-gray
    line-height: 18px
    margin-top: 4px

  &__licenses
    width: 240px
    display: flex
    gap: 8px
    flex-wrap: wrap
    @media only screen and (max-width: 1600px)
      width: 185px

    @media (max-width: 1170px)
      width: 140px

  &__fullname
    display: block
    width: 125px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &__link
    color: $dark-blue
    text-decoration: none
    &:hover
      text-decoration: underline
