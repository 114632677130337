@import '~assets/styles/variables.sass'

.add-worker

  &__table
    margin-top: 20px
    &-link
      color: $dark-blue
      text-decoration: none
      &:hover
        text-decoration: underline
    &-status
      width: 114px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      &--not-active .add-worker__table-link
        color: $dark-gray !important
    &--header-row
      th
        border-top: 0
        border-right: 0 !important
        border-left: 0 !important
        padding: 8px
    td
      border-right: 0 !important
      border-left: 0 !important
      border-bottom: 0 !important
      padding: 8px
    tr
      background: transparent !important
