.crew-details-page
  &__details
    display: flex
    flex-wrap: wrap
    gap: 16px
    margin-top: 25px
    margin-bottom: 16px
    &--single-detail
      flex: 0 0 calc((100% - 48px) / 4)
      height: 80px
