@import '~assets/styles/variables.sass'

.add-project-modal
  &__row
    margin-bottom: 16px !important
    &:last-child
      margin-bottom: 0 !important
  &__radio
    width: 100%
    &-label
      height: 60px
      font: 1.4rem $m
      color: #000000
      display: flex
      align-items: center
      gap: 10px
      padding: 0 16px
      background: $light
      border: 1px solid $light
      border-radius: 8px
      cursor: pointer
      user-select: none
    &-input
      display: none
    &-input:checked ~ &-label
      background: rgba(15, 183, 184, 0.08)
      border: 1px solid $primary
      svg path
        stroke: $primary