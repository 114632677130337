@import '~assets/styles/variables.sass'

.single-detail
  padding: 13px 24px
  background: #fff
  border: 1px solid $gray
  border-radius: 12px
  display: flex
  align-items: center
  gap: 1.8rem
  flex: 1
  &__icon
    flex: 0 0 24px
    path
      stroke: $primary
  &__label
    font-size: 1.5rem
    line-height: 21px

  &__title
    display: block
    font: 1.4rem $m
    line-height: 2.1rem
    color: $text-gray
    margin-bottom: 4px

  .ui-status
    height: 24px
