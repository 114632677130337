@import '~assets/styles/variables.sass'
  
.employees
  &__type-wrapper .ui-status
    font-size: 1.2rem
    padding: 2px 8px
    margin-top: 3px
  &__name, &__type
    font: 1.4rem $m !important
  &__phone
    display: inline-block
    font: 1.2rem $m
    color: $text-gray
    margin-top: 5px
    text-decoration: none
  &__licenses
    display: flex
    flex-wrap: wrap
    gap: 8px
  &__specialities
    width: 214px
    font-size: 1.4rem
    @include shortText
