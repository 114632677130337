@import '~assets/styles/variables.sass'

.map-modal
  &__paper
    max-width: 1200px !important
    width: 100% !important
    min-height: 634px
    border-radius: 12px !important
  &__close
    position: absolute
    right: 24px
    top: 24px
  &__content
    position: absolute
    bottom: 48px
    left: 50%
    transform: translateX(-50%)
    display: flex
    flex-direction: column
    align-items: center
  &__wrapper
    display: flex
    align-items: center
  &__address
    margin-bottom: 24px
    font: 2.4rem $m
    text-align: center
  &__confirm
    width: 212px
    min-height: 48px
    border-radius: 6px
    margin-left: 10px
  &__search
    width: 500px
    position: absolute
    top: 20px
    left: 50%
    transform: translateX(-50%)
    &-input
      .select-v2__input--root
        background: $white
        box-shadow: 0px 3px 8px rgba(0, 0, 0, .18)
        input::placeholder
          font: 1.4rem $r !important
