@import '~assets/styles/variables.sass'

*
  box-sizing: border-box

html
  font-size: 10px
  @media (max-width: 1350px)
    font-size: 8px

body
  font-family: $r
  font-size: 1.6rem

.container
  max-width: $container-width
  margin: 0 auto

.scroll
  &::-webkit-scrollbar
    width: 5px
    height: 5px

  &::-webkit-scrollbar-thumb
    background: rgba(#707D9F, 0.3)
    border: 7px none #F4F5F7
    border-radius: 33px

  &::-webkit-scrollbar-track
    background: #F4F5F7
    border: 100px none #F4F5F7
    border-radius: 50px

.css-j5h6pi-MuiPopper-root
  width: 340px !important
  .MuiPaper-root
    box-shadow: 0px 10px 24px rgba(35, 32, 32, 0.08)
    border-radius: 16px

.find-button
  background: transparent
  border: 0
  padding: 0
  display: flex
  align-content: center
  justify-content: center

.MuiAutocomplete-popper
  z-index: 99999 !important

.MuiClockPicker-root .MuiTypography-caption
  font: 1.2rem $b