@import '~assets/styles/variables.sass'
  
.daily-log
  &__reported
    font: 1.4rem $m
    margin-bottom: 8px
    &-by
      color: #696976
      margin: 0
    &-name
      color: $primary
      text-decoration: none
    &-date
      color: #696976
      opacity: .5