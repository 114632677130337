@import '~assets/styles/variables.sass'

.schedule-item
  display: flex
  flex-direction: column
  text-decoration: none
  &__inner
    // min-height: 194px
    border: 1px solid $gray
    padding: 16px 0
    max-width: 100%
    flex: 1
    &:first-child
      border-radius: 10px 10px 0 0
    &:last-child
      border-radius: 0 0 10px 10px
      border-top: none
  &__container
    margin: 0 16px
  &__statuses
    margin-top: 15px !important
    display: flex
    flex-direction: row !important
    gap: 8px
  &__title
    font: 1.6rem $sb
    margin-bottom: 9px
    text-decoration: none
    display: inline-block
    color: $dark
  &__item
    display: flex
    align-items: center
    color: $dark
    gap: 12px
    font: 1.5rem $m
    &--flex
      flex: 1
    &-date
      path
        fill: $dark-gray
  &__icon
    flex: 0 0 24px
    width: 24px
    height: 24px
    &--fill
      path
        fill: $dark-gray
  &__row
    display: flex
    align-items: center
    margin-bottom: 16px

  &__btns
    display: flex
    align-items: center
    margin-top: 20px
    gap: 16px
  &__btn
    height: 36px
    flex: 1
  &__date
    position: relative
    flex: 100%
    height: 48px
    display: flex
    align-items: center
    justify-content: center
    span
      font: 1.6rem $b
      position: relative
      z-index: 2
    &::after
      content: ""
      width: calc(100% + 48px)
      height: 100%
      border-top: 1px solid #EDEFF0
      border-bottom: 1px solid #EDEFF0
      background: #FAFBFC
      position: absolute
      top: 0
      left: -24px
      z-index: 1
