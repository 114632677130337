@import '~assets/styles/variables.sass'

.edit-daily-report-modal
  &__paper
    border-radius: 24px !important
  &__row
    margin-bottom: 16px !important
    &:last-child
      margin-bottom: 0 !important

  .upload-files__wrapper
    flex-wrap: wrap !important
    justify-content: flex-start
    .upload-files__image
      flex: 0 1 152px
      margin-bottom: 0