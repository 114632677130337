@import '~assets/styles/variables.sass'
  
.edit-profile-modal
  &__row
    margin-bottom: 16px !important
    &:last-child
      margin-bottom: 0 !important

  &__locale-warning
    font: 1.4rem $m
    color: $warning
    margin: 6px 0 0