@import '~assets/styles/variables.sass'

.schedule-item__item
  &__item
    display: flex
    align-items: center
    color: $dark
    gap: 12px
    font: 1.5rem $m

  &--success

    .schedule-item__icon
      path:nth-child(1)
        fill: $primary
      path:nth-child(2)
        fill: $primary
      path:nth-child(3)
        fill: white

  &--danger
    // color: $danger
    .schedule-item__icon
      path:nth-child(1)
        fill: $danger
      path:nth-child(2)
        fill: $danger
      path:nth-child(3)
        fill: white

  &--warning
    // color: $warning
    .schedule-item__icon
      path:nth-child(1)
        fill: $warning
      path:nth-child(2)
        fill: $warning
      path:nth-child(3)
        fill: white

  &--disabled
    // color: $dark-gray
    .schedule-item__icon
      path:nth-child(1)
        fill: $dark-gray
      path:nth-child(2)
        fill: $dark-gray
      path:nth-child(3)
        fill: white
