@import '~assets/styles/variables.sass'

.preview-image-modal
  position: relative
  &__loading
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &__image
    width: 100%
    height: 100%
    object-fit: contain
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &__close
    position: absolute
    top: 24px
    right: 24px

  &__download
    position: absolute
    bottom: 24px
    right: 28px
    width: max-content
    color: $primary
    svg path
      stroke: $primary

