@import '~assets/styles/variables.sass'

.ui-table
  width: 100%
  border-collapse: separate
  text-align: left
  overflow: auto

  &--first-tab
    .ui-table__th:first-child
      border-radius: 0

    .table-placeholder
      border-radius: 0 !important

  &--with-title
    .ui-title__title
      font: 1.8rem $b
    .ui-table__th:first-child, .ui-table__th:last-child
      border-radius: 0
  &__title
    min-height: 80px
    padding: 16px 25px
    background: #fff
    margin-bottom: 0
    border: 1px solid $gray
    border-bottom: 0
    border-radius: 10px 10px 0 0

    &--with-tabs
      border-top-left-radius: 0

  &__circular-progress
    width: 100%
    height: 707px
    display: flex
    align-items: center
    justify-content: center
    background: #FFFFFF
    border: 1px solid #F0F0F0
    border-radius: 10px
    &.ui-table--first-tab
      border-top-left-radius: 0

  thead, tbody
    background: #fff

  &__th
    padding: 14px 25px
    font: 1.4rem $m
    border-top: 1px solid $gray
    border-bottom: 1px solid $gray
    color: $text-gray
    white-space: nowrap
    background: $white
    position: sticky
    top: 0
    z-index: 2
    &--right
      text-align: right
    &--center
      text-align: center
    &--nowrap
      width: 2%
      white-space: nowrap
    &--hidden
      display: none
      padding: 0
    &--checkbox
      padding: 0 30px 0 5px
      width: 2%
      white-space: nowrap
    &--with-tabs
      border-radius: 0 !important
    &:nth-child(2)
      padding-left: 16px
    &:first-child
      border-left: 1px solid $gray
      border-radius: 10px 0 0 0
    &:last-child
      padding-right: 16px
      border-right: 1px solid $gray
      border-radius: 0 10px 0 0
  &__tr
    &--checked
      .ui-table__td
        cursor: pointer
    &--hoverable
      &:hover
        background: #ECECF2 !important
        transition: .2s all
        .ui-table__td
          cursor: pointer
    &:nth-child(2n)
      background: #FAFBFC
    &:last-child
      td
        border-bottom: 1px solid $gray
        &:first-child
          border-radius: 0 0 0 10px
          border-bottom: 1px solid $gray
        &:last-child
          border-radius: 0 0 10px 0
        &:only-child
          border-radius: 0 0 10px 10px // Round both bottom corners

  &__td
    padding: 11px 25px
    word-break: break-word
    transition: .3s all ease-in-out
    font: 1.5rem $m
    color: $dark-blue
    &--checkbox
      border: none
      padding: 0 30px 0 5px
      width: 2%
      white-space: nowrap
    &--hidden
      padding: 0 !important
      display: none
    &:nth-child(2)
      // border-left: $border-gray
      padding-left: 16px
    &:first-child
      border-left: 1px solid $gray
    &:last-child
      border-right: 1px solid $gray
      padding-right: 16px
    &--right
      text-align: right
    &--center
      text-align: center
    &--nowrap
      width: 2%
      white-space: nowrap
  &__loader
    flex: 1
  &__pagination
    display: flex
    justify-content: center
    margin-top: 3rem

@media (max-width: 1400px)
  .ui-table
    &__th, &__td
      padding: 10px 15px
