@import '~assets/styles/variables.sass'

.ui-action-button
  display: flex
  justify-content: center
  align-items: center
  width: 36px
  height: 36px
  background: #fff
  border: 1px solid $gray
  border-radius: 8px
  transition: all .3s ease
  &--download:hover
    background: $dark-blue
    border-color: $dark-blue
    svg path
    stroke: #ffffff

  &--delete:hover
    background: $danger
    border-color: $danger
    svg path
    stroke: #fff
  &--edit:hover
    background: $primary
    border-color: $primary
    svg path
    stroke: #fff
  &--close:hover
    background: $dark-blue
    border-color: $dark-blue
    svg path
    fill: #ffffff
    stroke: transparent
