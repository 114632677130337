@import '~assets/styles/variables.sass'

.ui-status
  font: 1.4rem $m
  line-height: 1.9rem
  padding: 4px 8px
  border-radius: 100px
  display: flex
  align-items: center
  justify-content: center
  max-width: fit-content
  white-space: nowrap
  &--primary
    color: $primary
    background: rgba(15, 183, 184, 0.08)
  &--danger
    color: $danger
    background: rgba(255, 75, 75, 0.08)
  &--secondary
    color: $text-gray
    background: rgba(128, 128, 166, 0.08)
  &--warning
    color: $warning
    background: rgba(255, 173, 51, 0.08)
  &--blue
    color: $blue
    background: rgba(71, 156, 255, 0.08)
  &--pink
    color: #FF83E4
    background: rgba(255, 131, 228, 0.08)
  &--purple
    color: #9747FF
    background: rgba(151, 71, 255, 0.08)
  &--red
    color: #FF4B4B
    background: rgba(255, 75, 75, 0.08)
  &--black
    color: #292D32
    background: rgba(41, 45, 50, 0.08)
  
