@import '~assets/styles/variables.sass'

.daily-report
  &__wrapper 
    background: white
    padding: 20px
    border-radius: 20px
  &__empty
    opacity: 0.5
    &--placeholder
      margin-top: 0
      margin-bottom: 20px

  &__loading
    height: 100px
    display: flex
    align-items: center
    justify-content: center
  // &__accident-wrapper
  //   border: 1px solid $primary
  //   border-radius: 8px
  //   padding: 16px
  //   padding-top: 0px
  //   margin-top: 20px
  // &__accident-title
  //   font: 2.0rem $b
  //   margin: 24px 0 16px
  //   color: $primary
  &__reported
    font: 1.4rem $m
    &-wrapper
      display: flex
      align-items: baseline
      gap: 5px
    &-title
      color: black
      margin: 0
      font-size: 24px
      font-weight: bold
      margin-right: 5px
      font-size: 30px
    &-author
      color: $primary
      font-weight: bold
    &-authorby
      color: $dark-gray
    &-name
      color: $primary
      text-decoration: none
      font-weight: 700

  &__cards
    display: flex
    flex-direction: column
    gap: 16px

  &__card

    &-description
      margin: 12px 0 0
      color: #696976
      font: 1.6rem $r
  &__attachments
    &-title
      font: 1.8rem $b
      margin: 24px 0 16px

    &-wrapper
      display: grid
      grid-template-columns: repeat(auto-fill, 152px)
      gap: 15px
      margin-top: 20px

    &-image
      width: 100%
      height: 100px
      border-radius: 8px
      object-fit: cover
      cursor: pointer
      border: 2px solid #e2e2e2
