@import '~assets/styles/variables.sass'
  
.license-details-page
  &__details
    display: flex
    flex-wrap: wrap
    gap: 16px
    margin-top: 25px
    margin-bottom: 16px
    &--single-detail
      flex: 0 0 calc((100% - 48px) / 4)
      height: 80px

      &--title .single-detail__label
        width: 100%

  &__number
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px
    font: 1.5rem $sb
    color: $dark-blue
    &-title
      color: $text-gray
      font: 1.4rem $m
      display: block
    &-img
      width: 36px
      height: 36px
      border-radius: 8px
      object-fit: cover
      cursor: pointer

    &-img-placeholder
      width: 36px
      height: 36px
      border-radius: 8px
      background: $gray
