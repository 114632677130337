@import '~assets/styles/variables.sass'

.schedule-details-page
  overflow: auto
  &__details
    display: flex
    flex-wrap: wrap
    gap: 16px
    margin-top: 25px
    margin-bottom: 16px
    &--single-detail
      flex: 0 0 calc((100% - 48px) / 4)
      min-height: 80px
      font: 1.5rem $m
      &--company
        .ui-status
          margin-top: 4px
  &--attendance
    margin-bottom: 25px
    width: 100%

  // Report Section
  &__report
    display: flex
    flex-direction: column
    gap: 20px
    padding-top: 20px

    &-section
      background-color: #fff
      padding: 20px
      border-radius: 10px
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)

      &__header
        font-weight: bold
        margin-bottom: 10px
        font-size: 16px
        color: #333

      &__message-input
        width: 100%
        height: 60px
        padding: 10px
        margin-bottom: 15px
        border-radius: 5px
        border: 1px solid #ddd
        font-size: 14px
        font-family: Arial, sans-serif
        background-color: #f9f9f9

      &__photo-grid
        display: grid
        grid-template-columns: repeat(6, 1fr)
        gap: 10px

      &__photo
        padding: 15px
        background-color: #f0f0f0
        text-align: center
        border-radius: 5px
        border: 1px solid #ddd

  // Download Button
  // &__download-button
  //   background: green
  //   color: white
  &__download-button
    color: $primary
    svg path
    stroke: $primary
  &__download-button-container
    flex: 2
    align-items: end
    margin-top: 20px
    display: flex
    justify-content: flex-end
    gap: 15px
    &__button
      padding: 10px 20px
      background-color: #28a745
      color: white
      border: none
      border-radius: 5px
      cursor: pointer
      font-size: 16px
      transition: background-color 0.3s ease

      &:hover
        background-color: #218838
