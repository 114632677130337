@import '~assets/styles/variables.sass'
  
.add-phone
  display: flex
  align-items: center
  gap: 8px
  font: 1.4rem $b
  color: $primary
  cursor: pointer
  user-select: none
  &__icon
    width: 20px
    height: 20px
    path
      fill: $primary

  &__delete
    cursor: pointer
    user-select: none
    svg path
      stroke: red
      fill: red