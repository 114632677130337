@import '~assets/styles/variables.sass'

.projects-page
  &__table-td
    &--name
      width: 200px
      @include shortText
    &--address
      width: 300px
      @include shortText
