@import '~assets/styles/variables.sass'
  
.add-project-modal
  &__subtitle
    font: 1.6rem $b
    margin-top: 0
  &__photo
    width: 90px
    height: 90px
    border-radius: 50%
  &__upload
    display: flex
    align-items: center
    gap: 24px
    &-image
      width: 90px
      height: 90px
      border-radius: 50%
      cursor: pointer
    &-title
      color: $primary
      font: 1.4rem $b
      margin-bottom: 7px
      cursor: pointer
    &-subtitle
      font: 1.4rem $r
      color: $text-gray
      margin: 0
    &-input
      display: none