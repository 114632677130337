@import '~assets/styles/variables.sass'

.schedule-item
  display: flex
  flex-direction: column
  text-decoration: none
  overflow: hidden

  &__table
    display: table
    width: 100%
    &--row
      display: table-row
      padding: 0 16px
      &-header
        background: $gray
        padding: 0
    &--cell
      display: table-cell
      text-align: center
      font: 1.2rem $r
      color: $dark-blue
      padding: 8px
      .schedule-item__item
        justify-content: center
      &-header
        padding: 5px
        color: #696976
