@import '~assets/styles/variables.sass'

.ui-tooltip
  &__tooltip
    color: #000 !important
    font: .9rem $m !important

.MuiTooltip-tooltip
  padding: 10px !important

.MuiTooltip-popper
  z-index: 99999 !important
