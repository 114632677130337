@import '~assets/styles/variables.sass'
  
.chat
  &__wrapper
    max-width: 731px
    height: calc(100vh - 96px)
    margin: 0 auto
    background: #fff
    display: flex
    flex-direction: column

  &__progress 
    display: flex !important
    margin: 100px auto

  &__header
    height: 72px
    display: flex
    padding: 15px 26px
    .back-button
      margin-bottom: 0
      gap: 30px

  &__placeholder
    height: 100%
    .table-placeholder
      border: 0

  &__company
    display: flex
    align-items: center
    gap: 15px
    &-shortname
      width: 41px
      height: 41px
      border-radius: 50%
      background: #9B51E0
      display: flex
      align-items: center
      justify-content: center
      font: 1.6rem $m
      color: #fff
      overflow: hidden
      user-select: none
    &-name
      font: 1.8rem $b

  &__content
    height: 100%
    flex: 1 1
    border-top: 1px solid $gray
    border-bottom: 1px solid $gray
    overflow: auto
    position: relative

  &__body
    &-input
      flex: 1
      textarea 
        font: 1.6rem $r

  &__messages
    padding: 16px 24px
    min-height: calc(100% - 74px)
    display: flex
    flex-direction: column
    gap: 16px

  &__message
    display: flex
    align-items: flex-end
    gap: 15px
    overflow-wrap: break-word
    &--reverse
      flex-direction: row-reverse
      .chat__message
        &-content
          max-width: 300px
          border-radius: 8px 8px 0 8px
          background: $primary
          color: #fff

        &-text
          font: 1.6rem $r
          color: inherit

        &-sent
          color: inherit

        &-reply
          background: rgba(0, 0, 0, 0.12)
          &-title
            color: inherit

    &-content
      max-width: 405px
      min-width: 300px
      background: $light
      padding: 12px 12px 27px
      border-radius: 8px 8px 8px 0
      position: relative
      color: $text-gray

    &-buttons
      display: flex
      flex-direction: column
      gap: 10px

    &-button
        width: 28px
        height: 28px
        background: $light
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        user-select: none

    &-name
      font: 1.5rem $sb
      margin-bottom: 4px
      color: $dark

    &-text
      font: 1.6rem $m

    &-sent
      position: absolute
      bottom: 8px
      right: 12px
      font: 1.2rem $r
      display: flex
      align-items: center
      gap: 4px

    &-file
      display: flex
      align-items: center
      gap: 8px
      cursor: pointer
      margin-bottom: 8px

    &-reply
      margin-bottom: 4px
      background: #fff
      border-radius: 8px
      padding: 12px
      position: relative
      &::after
        content: ''
        width: 2px
        height: calc(100% - 24px)
        position: absolute
        left: 0
        top: 50%
        background: #FCD462
        transform: translateY(-50%)
      &-title
        font: 1.4rem $sb
        color: $dark
        margin-bottom: 2px

      &-text
        font: 1.4rem $m

  &__footer
    min-height: 84px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 24px
    gap: 15px

  &__attach
    &-input
      display: none

  &__attach, &__send
    cursor: pointer
    &--disabled
      opacity: .7
    &:active
      opacity: .7

  &__reply
    position: sticky
    bottom: 0
    left: 0
    width: 100%
    height: 74px
    background: $light
    padding: 18px 24px
    display: flex
    align-items: center
    gap: 16px
    &-cancel
      cursor: pointer
    &-content
      flex: 1
      position: relative
      padding-left: 12px
      &::after
        content: ''
        width: 3px
        height: calc(100% - 5px)
        position: absolute
        left: 0
        top: 50%
        background: #FCD462
        transform: translateY(-50%)

    &-title
      font: 1.5rem $sb
      color: $dark

    &-text
      font: 1.5rem $r
      color: $text-gray
      margin-top: 2px
      width: 550px
      overflow: hidden
      text-overflow: ellipsis
        

  &__popover
    &-btn 
      min-width: 211px
      display: flex
      align-items: center
      justify-content: space-between
      padding: 18px 20px
      font: 1.8rem $sb
      cursor: pointer
      &:first-child
        border-bottom: 1px solid $gray

      &:hover
        background: rgba(0, 0, 0, 0.05)

      &.delete
        color: $danger