@import '~assets/styles/variables.sass'
  
.daily-log
  &__card
    padding: 1.4rem 1.4rem 1.4rem 2.4rem
    border-radius: 12px 
    border: 1px solid $gray
    &-title
      font: 1.6rem $m
    &-icon 
      width: 24px
      height: 24px
      path
        fill: $primary