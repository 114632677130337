@import '~assets/styles/variables.sass'

.submit-modal
  position: relative
  padding: 48px 0
  &__close
    position: absolute
    right: 24px
    top: 24px
  &__title
    font: 2.4rem/3.3rem $sb
    text-align: center
  &__content
    margin-top: 24px
    padding: 0 17px
    &-with-scroll
      padding: 0 33px
      overflow: auto
      max-height: 60vh
  &__btns
    display: flex
    gap: 15px
    justify-content: flex-end
  &__footer
    margin-top: 35px
    display: flex
    justify-content: flex-end
    align-items: center
    padding: 0 50px
    &--with-left-component
      justify-content: space-between
