@import '~assets/styles/variables.sass'

.attendance__table
  &__flex
    display: flex
    gap: 10px
    align-items: center
  &__row
    &-active
      // background: rgba(255,0,0, 0.1) !important
  &-time
    width: 140px
  &-img
    width: 36px
    height: 36px
    border-radius: 8px
    overflow: hidden
    background: $gray
    cursor: pointer

  &-full-name
    width: 120px
    display: flex
    align-items: center
    gap: 10px
    span
      @include shortText

  &-link
    color: $dark-blue
    display: block
    text-decoration: none
    &:hover
      text-decoration: underline
  &-col
    font: 1.5rem
    color: $dark-blue
    display: flex
    align-items: center
    justify-content: space-evenly
  &__icon-exit
    flex: 0 0 24px
    path
      // stroke: $dange
      fill: $danger
  &__icon-warning
    // flex: 0 0 24px
    height: 25px
    width: 25px
    path
      stroke: #FFAD33
      // fill: $danger
  &--flex
    display: flex
    align-items: center
    gap: 10px
