@import '~assets/styles/variables.sass'

.add-file-modal
  &__paper
    border-radius: 24px !important
  &__row
    margin-bottom: 16px !important
    &:last-child
      margin-bottom: 0 !important

  .upload-file
    margin-top: 25px
    &__error
      color: #d32f2f
      font: 1.4rem $m
    .file
      width: min-content
      display: flex
      align-items: center
      gap: 5px
      padding: 5px 8px
      background: rgba(237, 239, 240, 0.5)
      border-radius: 5px
      user-select: none
      &__icon
        flex: 0 0 20px
      &__name
        font: 1.4rem $m
        white-space: nowrap
      &__delete
        flex: 0 0 20px
        cursor: pointer
        path
          fill: $text-gray
      &__name
        color: #292D32
    &__label
      cursor: pointer
    &__title
      font: 1.6rem $b
      color: #292D32
    &__button
      display: flex
      gap: 8px
      align-items: center
      color: $primary
    &__files
      margin-top: 16px
      display: flex
      gap: 10px
      flex-wrap: wrap
    input
      display: none
