$el: 'Manrope-ExtraLight'
$l: 'Manrope-Light'
$r: 'Manrope-Regular'
$m: 'Manrope-Medium'
$sb: 'Manrope-SemiBold'
$b: 'Manrope-Bold'
$eb: 'Manrope-ExtraBold'

$header-height: 96px
$container-width: 1432px

$primary: #0FB7B8
$warning: #FFAD33
$danger: #FF4B4B
$dark-blue: #232B2E
$text-gray: #8080A6
$blue: #479CFF
$purple: #9747FF

$gray: #EDEFF0
$light-gray: #F6F7F8
$light: #F8F9FA
$dark-gray: #B0B8CC
$white: #ffffff
$dark: #292D32

@mixin shortText
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
