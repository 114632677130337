@import '~assets/styles/variables.sass'

.report__status
  background: $gray
  display: flex
  justify-content: space-evenly
  align-items: center
  padding: 7px 0px
  &__item
    background: $dark-gray
    color: white
    height: 30px
    width: 30px
    font-size: 14px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100%
    font-weight: 900
    opacity: 50%
    &--success
      background: $primary
