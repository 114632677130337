@import '~assets/styles/variables.sass'
  
.accident-modal
  &__cards
    display: flex
    flex-direction: column
    gap: 16px
  &__card
    background: $light-gray
    border-radius: 12px
    padding: 24px
    &-title
      display: flex
      align-items: center
      gap: 12px
      font: 1.8rem $b

    &-icon
      width: 24px
      height: 24px

    &-description
      margin: 12px 0 0
      color: #696976
      font: 1.6rem $r

  &__witness
    margin-top: 36px
    &-title
      font: 1.8rem $sb
      margin-bottom: 16px

    &-wrapper
      display: flex
      gap: 16px
      flex-wrap: wrap
      & > div
        flex: 1 1 calc(50% - 16px)
