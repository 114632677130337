@import '~assets/styles/variables.sass'

.ui-tabs
  position: relative
  top: 1px
  &__tab
    padding: 14px 24px !important
    font: 1.6rem $m
    font-weight: 400
    &__tab[aria-selected=true]
      font-weight: 700
