@import '~assets/styles/variables.sass'

.login-page
  height: calc(100vh - 40px)
  display: flex
  align-items: center
  justify-content: center
  &__wrapper
    max-width: 585px
    width: 100%
    background: $white
    border-radius: 24px
    position: relative
    padding: 48px 50px
  &__title
    font: 2.4rem/3.3rem $sb
    text-align: center
  &__content
    margin-top: 20px
  &__btns
    display: flex
    margin-top: 35px
    gap: 15px
    justify-content: flex-end
