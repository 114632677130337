
@font-face
  font-family: 'Manrope-Bold'
  src: url('../fonts/manrope/manrope-bold-webfont.eot')
  src: url('../fonts/manrope/manrope-bold-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-bold-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-bold-webfont.woff') format('woff'),url('../fonts/manrope/manrope-bold-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-bold-webfont.svg#manropebold') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-ExtraBold'
  src: url('../fonts/manrope/manrope-extrabold-webfont.eot')
  src: url('../fonts/manrope/manrope-extrabold-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-extrabold-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-extrabold-webfont.woff') format('woff'),url('../fonts/manrope/manrope-extrabold-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-extrabold-webfont.svg#manropeextrabold') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-ExtraLight'
  src: url('../fonts/manrope/manrope-extralight-webfont.eot')
  src: url('../fonts/manrope/manrope-extralight-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-extralight-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-extralight-webfont.woff') format('woff'),url('../fonts/manrope/manrope-extralight-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-extralight-webfont.svg#manropeextralight') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-Light'
  src: url('../fonts/manrope/manrope-light-webfont.eot')
  src: url('../fonts/manrope/manrope-light-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-light-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-light-webfont.woff') format('woff'),url('../fonts/manrope/manrope-light-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-light-webfont.svg#manropelight') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-Medium'
  src: url('../fonts/manrope/manrope-medium-webfont.eot')
  src: url('../fonts/manrope/manrope-medium-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-medium-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-medium-webfont.woff') format('woff'),url('../fonts/manrope/manrope-medium-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-medium-webfont.svg#manropemedium') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-Regular'
  src: url('../fonts/manrope/manrope-regular-webfont.eot')
  src: url('../fonts/manrope/manrope-regular-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-regular-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-regular-webfont.woff') format('woff'),url('../fonts/manrope/manrope-regular-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-regular-webfont.svg#manroperegular') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Manrope-SemiBold'
  src: url('../fonts/manrope/manrope-semibold-webfont.eot')
  src: url('../fonts/manrope/manrope-semibold-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/manrope/manrope-semibold-webfont.woff2') format('woff2'),url('../fonts/manrope/manrope-semibold-webfont.woff') format('woff'),url('../fonts/manrope/manrope-semibold-webfont.ttf') format('truetype'),url('../fonts/manrope/manrope-semibold-webfont.svg#manropesemibold') format('svg')
  font-weight: normal
  font-style: normal
