@import '~assets/styles/variables'

.create-schedule
  &__content
    display: flex
    gap: 24px
  &__left
    width: 450px
    min-width: 400px
    padding: 24px 0
    border-radius: 10px
    background: #fff
    @media (max-width: 1350px)
      min-width: 320px
      width: 320px
  &__right
    flex: 1
  &__form
    &--btn
      height: 45px
      flex: 1

  &__upload-file
    margin-bottom: 25px !important
    &--label
      cursor: pointer
    &--title
      font: 1.6rem $b
      color: #292D32
    &--button
      display: flex
      gap: 8px
      align-items: center
      color: $primary
    &--files
      margin-top: 16px
      display: flex
      gap: 10px
      flex-wrap: wrap
    input
      display: none

  &__table
    &--cell
      border-radius: 0 !important
      border-inline: none !important
    &--row
      td
        border: none !important

  &__time-input
    &--option
      font: 1.5rem $m !important
      display: flex !important
      text-align: center
      justify-content: center !important

  &__download-file
    cursor: pointer
    &:hover
      text-decoration: underline


  &__confirm-delete-modal
    &-text
      font: 1.6rem/1.6 $r
      color: $text-gray
      text-align: center
    &-options
      margin-left: 15px !important
      margin-top: 15px !important
    .submit-modal__footer
      justify-content: center