@import '~assets/styles/variables.sass'

.ui-input
  &--with-icon
    .MuiInputBase-root
      padding-right: 14px !important
  // font: 1.5rem $m !important
  // // height: 100% !important
  // padding: 0 10px !important
  // -webkit-text-fill-color: #000 !important
  // color: #000 !important
  // text-overflow: clip !important
  // &--search-disabled
  //   caret-color: transparent
  // &--root
  //   height: 45px
  //   .MuiOutlinedInput-notchedOutline
  //     border: solid 1px $gray !important
  //     border-radius: 11px
