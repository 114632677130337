@import '~assets/styles/variables.sass'

.date-divider
  display: flex
  align-items: center
  justify-content: center
  position: relative
  cursor: default
  user-select: none
  &__date
    font: 1.6rem $m
    color: $text-gray
    max-width: fit-content
