@import '~assets/styles/variables'

.header-item
  color: #000
  font: 1.6rem $m
  text-decoration: none
  width: 136px
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  transition: .1s background
  user-select: none
  cursor: pointer
  &:hover
    background: $light
    transition: .1s background
  &__inner
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    height: 50px
  &__icon
    path
      stroke: $dark-gray

  &__active
    .header-item__icon
      path
        fill: $primary
        stroke: none
