@import '~assets/styles/variables.sass'
  
.upload-files
  &__title
      font: 1.6rem $b
  &__desc
    font: 1.4rem $m
    color: $text-gray
  &__btn
    cursor: pointer
    color: $primary
    
  &__label
    input
      display: none

  &__image
    width: 50%
    height: 152px
    position: relative
    border-radius: 8px
    overflow: hidden
    margin-bottom: 24px
    img
      width: 100%
      height: 100%
    .ui-action-button
      width: 20px
      height: 20px
      padding: 4px
      border-radius: 50%
      background: $white
      border-color: $white
      position: absolute
      top: 10px
      right: 10px
      &:hover
        svg path
          fill: $dark
          stroke: transparent