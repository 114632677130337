@import '~assets/styles/variables.sass'
  
.reports-placeholder
  background: white
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  border-radius: 10px
  margin: 100px 0
  &__icon-wrapper
    width: 90px
    height: 90px
    border-radius: 50%
    background: $light-gray
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 36px
      height: 36px
  &__title
    margin-top: 24px
    margin-bottom: 8px
    font: 1.8rem $b
    color: $dark
  &__subtitle
    max-width: 250px
    color: $text-gray
    font: 1.6rem $r
    text-align: center