@import '~assets/styles/variables.sass'

.ui-title
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 2rem
  &__back
    margin: 0 !important
  &__title
    font: 2.4rem $sb
  &__right, &__left
    display: flex
    align-items: center
    gap: 24px
  &__left
    gap: 0
