@import '~assets/styles/variables.sass'
  
.license
  &__number
    display: flex
    align-items: center
    gap: 16px
    &-img
      width: 36px
      height: 36px
      border-radius: 8px
      object-fit: cover

    &-img-placeholder
      width: 36px
      height: 36px
      border-radius: 8px
      background: $gray