@import '~assets/styles/variables.sass'

.ui-accordion
  &__loader
    display: flex
    align-items: center
    justify-content: center
  &__item
    &--title
      font: 1.8rem $sb
      display: flex
      gap: 12px
      align-items: center
    &--arrow
      width: 7px
      height: 14px
    &--content
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between

    &--summary
      height: 75px
      padding-right: 0 !important

  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters
    margin-top: 13px !important
    margin-bottom: 13px !important
