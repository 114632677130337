@import '~assets/styles/variables.sass'

.add-supervisor-modal
	// &__inputs
	// 	height: 414px
	// 	overflow: auto
	.submit-modal__content-with-scroll
		height: 414px
	&__paper
		border-radius: 24px !important
	&__row
		margin-bottom: 16px !important
		&:last-child
			margin-bottom: 0 !important

	.ui-table__circular-progress
		height: 200px !important

	&__table
		
		&-link
			color: $dark-blue
			text-decoration: none
			&:hover
				text-decoration: underline
		&-status
			display: flex
			align-items: center
			gap: 12px
			&--not-active .add-worker__table-link
				color: $dark-gray !important
		&--header-row
			th
				padding: 8px
				&:first-child
					padding-right: 0
		td
			padding: 8px
		tr
			background: transparent !important
			td:first-child
				padding-right: 0