@import '~assets/styles/variables.sass'

.ui-pagination
  &__item
    font: 1.6rem $m !important
    margin: 0 1rem !important
    min-width: 48px !important
    height: 48px !important
    border-radius: 100% !important
    border: 1px solid $gray !important
    background: #fff !important
    color: $text-gray !important
    &--selected
      background-color: $primary !important
      color: #fff !important
    &--ellipsis
      display: flex
      justify-content: center
      align-items: center
      &:first-child
        font-size: 0 !important
      &::after
        content: '...'
        font-size: 1.6rem !important
        letter-spacing: 1px
    &--arrows
      opacity: 1 !important
      background: transparent !important
      border: none !important
      .MuiSvgIcon-root
        color: #000 !important
        font-size: 2.6rem !important
