@import '~assets/styles/variables.sass'
  
.notifications
  height: calc(100vh - 96px)
  overflow: hidden
  &.show-shadow
    position: relative
    &::after
      content: ''
      width: 100%
      height: 181px
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%)
      position: absolute
      bottom: 0
      left: 0

  &__title
    padding: 24px 36px
    font: 2.4rem $b
    background: $light-gray
  &__wrapper
    height: 100%
    padding: 16px 21px 0 36px
  &__content 
    height: calc(100% - 85px)
    overflow: auto

  &__list
    display: flex
    flex-direction: column
    gap: 24px
    padding-bottom: 20px
  
  &__devider
    &-title
      font: 1.6rem $b
      margin-bottom: 16px

  &__list
    display: flex
    flex-direction: column
    gap: 30px

  &__placeholder
    height: calc(100% - 85px)
    .table-placeholder
      border: 0
      height: 100%

  &__item
    display: flex
    justify-content: space-between
    padding-right: 21px

    &-content
      flex: 0 0 333px

    &-title
      font: 1.6rem $sb

    &-description
      font: 1.4rem $r
      line-height: 22px
      color: $text-gray
      margin-top: 6px
      a
        color: $primary
        font-family: $m
      b
        font-family: $sb
        color: $dark

    &-time
      margin-left: 27px
      font: 1.4rem $m
      color: $text-gray
      white-space: nowrap

    &-btn
      font: 1.4rem $sb
      min-width: auto !important
      height: 41px
      padding: 10px 16px !important
      margin-top: 12px
      display: inline-block

    &-bullet
      flex: 0 0 8px
      height: 8px
      border-radius: 50%
      background: $dark-gray
      margin: 8px 12px 0 5px
      &.primary
        background: $primary
      &.purple
        background: $purple
      &.yellow
        background: $warning
      &.danger
        background: $danger