@import '~assets/styles/variables.sass'

.layout
  display: flex
  flex-direction: column
  min-height: 100vh
  &__content
    background: $light
    flex: 1
  &__container
    padding: 20px
    &.chat
      padding: 0
