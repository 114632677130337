@import '~assets/styles/variables'

.add-schedule-crew-modal
  &__loading
    margin: 40px auto 0
    display: flex
    justify-content: center

  &__table-title
    width: 100%
    font: 1.4rem $sb
    color: $text-gray

  &__column
    height: 400px
    overflow: auto
    margin-top: 24px !important

  &__table-head
    width: 100%
    padding-bottom: 14px
    border-bottom: 1px solid #EDEFF0

  &__members
    width: 100%

  .MuiFormControlLabel-root:first-child
    height: 20px !important
    margin-bottom: 15px !important

  &__member
    height: 54px
    display: flex
    align-items: center
    font: 1.6rem $sb
    &:first-child
      height: 20px !important
      margin-bottom: 15px !important