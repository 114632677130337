@import '~assets/styles/variables.sass'

.daily-report
  &__loading
    height: 100px
    display: flex
    align-items: center
    justify-content: center

  &__reported
    font: 1.4rem $m
    &-title
      color: #696976
      margin: 0
    &-name
      color: $primary
      text-decoration: none
    
  &__cards
    display: flex
    flex-direction: column
    gap: 16px

  &__card
    background: $light-gray
    border-radius: 12px
    padding: 24px
    &-title
      display: flex
      align-items: center
      gap: 12px
      font: 1.8rem $b

    &-icon
      width: 24px
      height: 24px

    &-description
      margin: 12px 0 0
      color: #696976
      font: 1.6rem $r
      overflow: hidden

  &__attachments
    &-title
      font: 1.8rem $b
      margin: 24px 0 16px

    &-wrapper
      display: grid
      grid-template-columns: repeat(auto-fill, 152px)
      gap: 15px

    &-image
      width: 100%
      height: 152px
      border-radius: 8px
      object-fit: cover
      cursor: pointer